import React from 'react';
import { CheckGreenIcon, ExclamationIcon, QuestionMarkIcon } from 'assets/icons';
import classNames from 'classnames';
import { ECommissionState } from 'modules/commissions/types';

import styles from './CommissionStateIndicator.module.scss';

export interface ICommissionStateIndicatorProps {
    state: ECommissionState;
}

const stateToIcon: Record<ECommissionState, React.ReactNode> = {
    [ECommissionState.Enquiry]: <QuestionMarkIcon className={classNames(styles.icon, styles['ENQUIRY'])} />,
    [ECommissionState.Incomplete]: <ExclamationIcon className={classNames(styles.icon, styles['INCOMPLETE'])} />,
    [ECommissionState.Complete]: <CheckGreenIcon className={classNames(styles.icon, styles['COMPLETE'])} />,
};

export const CommissionStateIndicator: React.FC<ICommissionStateIndicatorProps> = ({ state }) => {
    return <>{stateToIcon[state]}</>;
};
