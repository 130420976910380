import type { IconProps } from './types';

export const QuestionMarkIcon: React.FC<IconProps> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_565_781)">
                <path
                    d="M12 7.45027C11.6073 7.44547 11.2287 7.59674 10.9475 7.87087C10.6662 8.145 10.5053 8.51956 10.5 8.91227C10.4966 9.17414 10.3893 9.42393 10.2018 9.60675C10.0143 9.78958 9.76187 9.89047 9.5 9.88727C9.23813 9.89047 8.9857 9.78958 8.79819 9.60675C8.61068 9.42393 8.50343 9.17414 8.5 8.91227C8.51186 7.99574 8.88722 7.12144 9.54356 6.48161C10.1999 5.84178 11.0835 5.48879 12 5.50027C12.9165 5.48879 13.8001 5.84178 14.4564 6.48161C15.1128 7.12144 15.4881 7.99574 15.5 8.91227C15.4996 9.3542 15.412 9.79171 15.2423 10.1998C15.0726 10.6078 14.8241 10.9784 14.511 11.2903C14.388 11.4133 14.271 11.5283 14.159 11.6373C13.8889 11.8878 13.6348 12.155 13.398 12.4373C13.1711 12.6704 13.0301 12.9736 12.998 13.2973V14.2723C12.998 14.5375 12.8926 14.7918 12.7051 14.9794C12.5176 15.1669 12.2632 15.2723 11.998 15.2723C11.7328 15.2723 11.4784 15.1669 11.2909 14.9794C11.1034 14.7918 10.998 14.5375 10.998 14.2723V13.3003C11.0266 12.5406 11.3158 11.8139 11.817 11.2423C12.1286 10.8697 12.4625 10.5163 12.817 10.1843C12.911 10.0933 12.998 10.0083 13.075 9.93127C13.2094 9.79778 13.316 9.63902 13.3888 9.46413C13.4616 9.28925 13.499 9.10169 13.499 8.91227C13.4937 8.51974 13.3329 8.14533 13.0519 7.87123C12.7709 7.59713 12.3925 7.44573 12 7.45027Z"
                    fill="currentColor"
                />
                <path
                    d="M12 18.5C12.1978 18.5 12.3911 18.4414 12.5556 18.3315C12.72 18.2216 12.8482 18.0654 12.9239 17.8827C12.9996 17.7 13.0194 17.4989 12.9808 17.3049C12.9422 17.1109 12.847 16.9327 12.7071 16.7929C12.5673 16.653 12.3891 16.5578 12.1951 16.5192C12.0011 16.4806 11.8 16.5004 11.6173 16.5761C11.4346 16.6518 11.2784 16.78 11.1685 16.9444C11.0586 17.1089 11 17.3022 11 17.5C11 17.7652 11.1054 18.0196 11.2929 18.2071C11.4804 18.3946 11.7348 18.5 12 18.5Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_565_781">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
