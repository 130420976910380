import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconEmail, IconExport } from 'assets/icons';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import { User } from 'core/auth/types';
import { CommissionSchema, ECommissionState, usePostNewCommissionMutation } from 'modules/commissions';
import { useSelectedDispatchers } from 'modules/commissions/hooks/useSelectedDispatchers';
import { transformCommissionData } from 'modules/commissions/utils';
import { Currency } from 'modules/common';
import { PageContent, Subheader } from 'modules/layout';
import { BackButton, Button } from 'modules/ui';

import { CommissionsBasicForm } from '../../../form/components/CommissionsBasicForm/CommissionsBasicForm';
import { SelectedTabOptions } from '../CommissionHeaderSwitch/types';

export const CommissionsNewPage = () => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.commissions'));

    const navigate = useNavigate();
    const [forceSubmitForm, setForceSubmitForm] = useState(false);
    const [showInquiryModal, setShowInquiryModal] = useState(false);
    const { selectedDispatchers } = useSelectedDispatchers();
    const [disableExportButton, setDisableExportButton] = useState(true);

    const [postNewCommission, { isLoading: isSubmittingFormLoading }] = usePostNewCommissionMutation();

    const prepareData = async ({
        data,
        editedBy,
        submittedFromExportButton,
    }: {
        data: CommissionSchema;
        editedBy: User;
        submittedFromExportButton: boolean;
        oldData?: CommissionSchema;
    }) => {
        const oldData: CommissionSchema = {
            qid: null,
            addedBy: '',
            editedBy: '',
            customer_id: 0,
            // basic info not used in form
            relation: '',
            week: 0,
            number: 0,
            enteredCarrierByNumber: 0,
            year: 0,
            // status
            orderConfirmationSent: false,
            carrierOrderSent: false,
            loadingConfirmationSent: false,
            dischargeConfirmationSent: false,
            // Customer
            // company: '',
            priceCustomer: 0,
            currencyCustomer: '',
            invNumber: 0,
            orderNumber: '',
            orderDate: '',
            customerContact_id: 0,
            // carrier assignment
            carrier_id: null,
            dispatcher_id: null,
            enteredCarrierBy: '',
            carrierDriver: '',
            carrierGsm: '',
            carrierRegistrationPlate: '',
            priceCarrier: 0,
            currencyCarrier: Currency['CZK'],
            // other
            disposition: '',
            note: '',
            notification: false,
            commissionLoadingDischarge: [],
            commissionitem: [],
            vat: '0',
            invoice_id: null,
            exchangeRateCustomer: 24,
            exchangeRateCarrier: 24,
            state: ECommissionState.Incomplete,
        };

        const formatted = transformCommissionData({ currentData: data, editedBy, oldData });

        try {
            const response = await postNewCommission({
                data: formatted,
            }).unwrap();
            toast.success(t('commissions.created'));
            if (submittedFromExportButton) {
                return navigate(`${config.routes.commissions.list}/export/${response.commission_id}`);
            }
            navigate(`${config.routes.commissions.detail}`.replace(':id', `${response.commission_id}`));
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    return (
        <PageContent
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    endSlot={
                        <>
                            <Button
                                onClick={() => {
                                    setForceSubmitForm(true);
                                }}
                                type="button"
                                variant="secondary"
                                disabled={disableExportButton}
                            >
                                <IconExport />
                                {t('commissions.form.saveAndExport')}
                            </Button>
                            <Button
                                onClick={() => setShowInquiryModal(true)}
                                disabled={selectedDispatchers.length === 0}
                                variant="secondary"
                            >
                                <IconEmail iconBackgroundColor="#1E2124" />
                                {t('common.subheader.email')}
                            </Button>
                            <Button
                                isLoading={isSubmittingFormLoading}
                                disabled={isSubmittingFormLoading}
                                form="commission-form"
                                type="submit"
                            >
                                {t('commissions.form.save')}
                            </Button>
                        </>
                    }
                    title={t('commissions.newCommission')}
                />
            }
        >
            <CommissionsBasicForm
                prepareData={prepareData}
                showInquiryModal={showInquiryModal}
                setShowInquiryModal={setShowInquiryModal}
                forceSubmitForm={forceSubmitForm}
                setDisableExportButton={setDisableExportButton}
                selectedTab={SelectedTabOptions.Configuration}
            />
        </PageContent>
    );
};
